import React from "react"
import Wrapper from "../components/wrapper";
import Works from "../components/works";
import Client from "../components/client";
import Testimonial from "../components/testimonial";
import SectionTitle from "../components/sectionTitle";

const Portfolio = ({location}) => {
    return (
        <Wrapper
            customTitle={"Website Design & Mobile Apps Portfolio in Malaysia | Appleseeds"} 
            customDescription={"Website design, mobile application & backend systems development company that manages Malaysia's Top 20 news portal and hospitality websites & mobile apps."} 
            customKeyword={"web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"} 
            // customOgImage={}
            slug={location.pathname}
            >
            <section className="h-screen relative">
                <div className="absolute top-0 right-0 left-0 bottom-0 w-full h-full z-20 bg-black bg-opacity-30">
                    <div className="container mx-auto px-4 flex w-full h-full items-center">
                        <SectionTitle 
                        wrapperClassName=""
                        alignCenter={true}
                        title="Our Portfolio"
                        minititle=""
                        type="h1"
                        invert={true}
                        childrenClassName="w-full md:3/4 lg:w-4/6 xl:w-2/4"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                            <p>
                            It’s never about the size of the project. It’s about knowing the work that we do will impact millions of people. It’s about the spark of exhilaration when the right people come together to bring life to electrifying ideas.
                            </p>
                        </SectionTitle>
                    </div>
                </div>
                <video className="absolute top-0 right-0 bottom-0 left-0 w-full h-full -z-1 object-cover" poster="/cover-work-poster.png" muted autoPlay loop playsInline>
                    <source src="/web-designer-working.mp4" type="video/mp4"/>
                </video>
            </section>

            <Works filterWorks={false}/>
            <Testimonial/>
            <Client/>

        </Wrapper>
    );
};
  
export default Portfolio;